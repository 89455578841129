import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { app } from "../../firebase";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";
const firebaseAuth = getAuth(app);
const provider = new GoogleAuthProvider();

export const createUser = createAsyncThunk(
  "createUser",
  async (userDetails) => {
    const { email, password } = userDetails;
    const user = await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    return user.user;
  }
);

export const signinUser = createAsyncThunk(
  "signinUser",
  async (userDetails) => {
    const { email, password } = userDetails;
    const user = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    return user.user;
  }
);

export const signupWithGoogle = createAsyncThunk(
  "signupWithGoogle",
  async () => {
    const user = await signInWithPopup(firebaseAuth, provider);
    return user.user;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    error: null,
    loading: false,
  },
  //   reducers: {
  //     logoutUser: (state, action) => {
  //       state.userLoggedIn = null;
  //     },
  //   },

  extraReducers: (builder) => {
    builder.addCase(createUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(signinUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(signinUser.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(signinUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(signupWithGoogle.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(signupWithGoogle.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(signupWithGoogle.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const {} = authSlice.actions;
