import React, { Suspense, lazy } from "react";
import Header from "./components/header/Header";
import { ProtectRoute } from "./components/ProtectRoute";
import { Route, Routes } from "react-router-dom";

// Lazy load components
const Signup = lazy(() => import("./pages/signup/Signup"));
const Login = lazy(() => import("./pages/login/Login"));
const Hostel = lazy(() => import("./pages/hostels/Hostel"));
const AddHostel = lazy(() => import("./pages/add-hostel/AddHostel"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const HostelDetails = lazy(() =>
  import("./pages/hostel-details/HostelDetails")
);
const UserSpecificHostels = lazy(() =>
  import("./pages/userSpecificHostels/UserSpecificHostels")
);
const UpdateHostel = lazy(() => import("./pages/update-hostel/UpdateHostel"));
const Home = lazy(() => import("./pages/home/Home"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const SignupWithPhone = lazy(() =>
  import("./pages/signup-with-phone/SignupWithPhone")
);

function App() {
  return (
    <div>
      <Header />

      {/* Suspense fallback for lazy-loaded components */}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<ProtectRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/add-hostel" element={<AddHostel />} />
            <Route
              path="/profile/my-hostels"
              element={<UserSpecificHostels />}
            />
            <Route path="/update-hostel/:hostelId" element={<UpdateHostel />} />
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="/contact/:hostelId" element={<Contact />} />
          <Route path="/hostels" element={<Hostel />} />
          <Route path="/hostel-details/:hostelId" element={<HostelDetails />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup/phone" element={<SignupWithPhone />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
