import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { app } from "../../firebase";
const storage = getStorage(app);

export const getHostels = createAsyncThunk(
  "getHostels",
  async (pageNumber = 1) => {
    let startAt = -10000;
    const res = await axios.get(
      `${process.env.REACT_APP_REALTIME_DB_URL}/hostels.json?orderBy="priority"&limitToFirst=200&startAt=${startAt}`
    );

    // Convert the response data to an array and sort by priority descending
    const hostels = Object.entries(res.data || {}).map(([id, data]) => ({
      id,
      ...data,
    }));

    // Sort by priority in descending order
    hostels.sort((a, b) => a.priority - b.priority);

    console.log(hostels);
    return hostels;
  }
);

export const getMyHostels = createAsyncThunk("getMyHostels", async (userId) => {
  const res = await axios.get(
    `${process.env.REACT_APP_REALTIME_DB_URL}/hostels.json?orderBy="uid"&equalTo="${userId}"`
  );
  return res.data;
});

export const hostelSlice = createSlice({
  name: "hostels",
  initialState: {
    loading: false,
    error: null,
    hostels: [],
    myHostels: [],
  },
  reducers: {
    // getHostel: (state, action) => {
    //   console.log("gethostel called", action.payload);
    //   state.specificHostelDetails = state.hostels.find((hostel) => {
    //     return hostel.id == action.payload;
    //   });
    // }
  },

  extraReducers: (builder) => {
    builder.addCase(getHostels.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getHostels.fulfilled, (state, action) => {
      state.loading = false;
      state.hostels = action.payload;
    });
    builder.addCase(getHostels.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getMyHostels.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getMyHostels.fulfilled, (state, action) => {
      state.loading = false;
      const newHostels = [];
      Object.entries(action.payload).forEach(([k, v]) => {
        newHostels.push({ id: k, ...v });
      });
      state.myHostels = action.payload.hostels;
      state.myHostels = newHostels;
    });
    builder.addCase(getMyHostels.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// export const {  } = hostelSlice.actions;
